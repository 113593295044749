$gutter: .5rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
//@import "assets/demo001/flags/flags.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
  //font-family: 'Work Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
}
.widget-news {
  margin: 0!important;
}
:host ::ng-deep .p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.accion-tabla{
  cursor: pointer;
  margin-right: 15px;
  transition: all ease 1ms;
}
.accion-tabla:hover{
  color: var(--primary-color)
 }
.accion-tabla_izq{
  cursor: pointer;
  margin-left: 15px;
  transition: all ease 1ms;
}
.accion-tabla_izq:hover{
  color: var(--primary-color)
}
.accion_menu_venta{
  cursor: pointer;
  transition: all ease 1ms;
}
.accion_menu_venta:hover{
  color: var(--primary-color)
}
.activo{
  color: darkgreen;
}
.inactivo{
  color: darkred;
  background: #4db6ef;
}
.comisionOn{
  background: rgba(0, 255, 53, 0.2);
}

.comisionOff{
  color: white;
  background: rgba(139, 0, 0, 0.45);
}

.p-knob-text{
  font-size:0.65rem !important;
}

/*************************** LOADER ************************/
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #5E81AC;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.chip-aprobado {
  background:  linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
}
.chip-rechazado {
  background: linear-gradient(256deg, #c4656d 0%, #d088a5 100%);
}
.chip-pendiente {
  background: linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);;
}
.lista_credito{
  list-style: none;
  padding:10px;
}

.chip-disponible {
  background:  linear-gradient(256deg, #708090FF 0%, #708090FF 100%);
  color: white;
}
.chip-utilizado {
  background: linear-gradient(256deg, #48D1CCFF 0%, #48D1CCFF 100%);
}
.chip-saldo-bajo {
  background: linear-gradient(256deg, #c4656d 0%, #d088a5 100%);
}
.chip-padding{
  margin: 15px;
  font-size: 1.2em;
}
.texto_breadcumb_ventas{
  font-size: 1.2em;
  font-weight: bold;
}
.no-autorizado{
  padding: 10px;
  border-radius: 5px;
  background: linear-gradient(256deg, #c4656d 0%, #d088a5 100%);
  color: white;
}
.autorizado{
  padding: 10px;
  border-radius: 5px;
  background:  linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  color: white;
}
.wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host ::ng-deep .p-rowgroup-footer td {
  font-weight: 700;
}

:host ::ng-deep .p-rowgroup-header {
  span {
    font-weight: 700;
  }

  .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
  }
}

.tabla {
  width: 100%;
}

.tabla_calculadora {
  width: 100%;
  font-size:1.2em;
}
.tabla_calculadora th{
  color: #4c566a;
  text-align: left;
}
.tabla_calculadora td{
  color: #4c566a
}

.subir_soporte {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.caption_content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.caption_content_only_input {
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
}

.info_card {
  margin: 25px 0 25px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  border: #2596be 1px solid;
  color: #212529;
}

.info_card i{
  font-size:2em;
  padding-right:15px
}
.item_center {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content:center;
  align-items: center;
}

.categoria_item {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content:center;
  align-items: center;
  font-size: 1.4em;
}

:host ::ng-deep .tabview-custom { i, span { vertical-align: middle; } span { margin: 0 .8rem; }}
:host ::ng-deep .p-button { margin-right: .25rem;}
:host ::ng-deep .p-tabview p {line-height: 2; margin: 0;}


.product-item {
  display: flex;
  align-items: center;
  padding: .5rem;
  width: 100%;

  img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
  }

  .product-category {
    vertical-align: middle;
    line-height: 1;
  }
}

.tabla_detalle {
  width: 100%;
  text-align: left;

}
.tabla_detalle th{
  width: 40%;
  padding:4px;
}
.tabla_detalle td {
  width: 60%;
  text-align: right;
}

.total_card{
  display: flex;
  flex-flow: column wrap;
  align-items: end;
  background: #eceff4;
  padding: 10px;
  border-radius: 10px;
}
.total_card-value{
  color: #4c566a;
  font-size: 2em;
  font-weight: bold;
}
.total_card_title{
  color: gray;
  font-size: 0.8em;
}
.total_card_container{
  padding-top: 20px;
  float: right;
  width: 100%;
}
.total_card_title_sell{
  color: gray;
  font-size: 1em;
}
.total_card-value_sell{
  color: #4c566a;
  font-size: 3em;
  font-weight: bold;
}
.total_card_title_nodo{
  color: gray;
  font-size: 1em;
}
.total_card-value_nodo{
  color: #4c566a;
  font-size: 1.5em;
  font-weight: bold;
}
.total_card-value_nodo{
  color: #4c566a;
  font-size: 1.3em;
  font-weight: bold;
}
.card_header{
  display: flex;
  justify-content: space-between;
}
.card_header_content_titles{
  padding-right: 10px;
  display: flex;
  flex-flow: column wrap;
  align-items: end;
}
.total_card_nodo{
  display: flex;
  flex-flow: column wrap;
  background: #eceff4;
  padding: 10px;
  border-radius: 10px;
}